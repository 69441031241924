// constants.ts
export const DEFAULT_AGENT_STATS = {
  messagesSeen: 0,
  voiceMemosSeen: 0,
  videoNotesSeen: 0,
  linksSeen: 0,
  filesSeen: 0,
  photosSeen: 0,
  documentsSeen: 0,
  messagesSent: 0,
  voiceMemosSent: 0,
  videoNotesSent: 0,
};

export enum AgentGoal {
  GeneralChat = "general_chat",
  Finance = "finance",
  NutritionAndFitness = "nutrition_and_fitness",
  Custom = "custom",
}

export const DEFAULT_AGENT_SETTINGS = {
  language: "English",
  mainGoal: AgentGoal.GeneralChat,
  temperature: 0.5,
};
