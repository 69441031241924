import { _getHeaders, URL_BASE } from "./shared";

export interface AgentSettings {
  agent_settings: {
    language: string;
    mainGoal: string;
    customGoalPrompt?: string;
    temperature: number;
    knowledgeBase?: string;
  };
  chat_settings: {
    replyType: string;
    voiceType?: string;
    messageLength?: string;
  };
  stats: {
    messagesSeen: number;
    linksSeen: number;
    filesSeen: number;
    photosSeen: number;
    documentsSeen: number;
    messagesSent: number;
    voiceMemosSent: number;
    videoNotesSent: number;
    membersCount?: number;
  };
  credits: number;
}

export async function getAgentSettings(): Promise<AgentSettings> {
  const url = `${URL_BASE}/agent-settings/me`;
  console.log('Fetching agent settings from:', url);
  
  const response = await fetch(url, {
    method: "GET",
    headers: _getHeaders(),
  });

  if (!response.ok) {
    console.error('Failed to fetch agent settings:', response.status, response.statusText);
    throw new Error(`Failed to fetch agent settings: ${response.statusText}`);
  }

  return response.json();
}

export async function updateAgentSettings(settings: Partial<AgentSettings>) {
  const url = `${URL_BASE}/agent-settings/me`;
  console.log('Updating agent settings at:', url, settings);

  const requestPayload = {
    agent_settings: settings.agent_settings,
    chat_settings: settings.chat_settings || {},
    stats: settings.stats || {},
  };
  
  const response = await fetch(url, {
    method: "PUT",
    headers: _getHeaders(),
    body: JSON.stringify(requestPayload),
  });

  if (!response.ok) {
    const errorText = await response.text();
    console.error('Failed to update agent settings:', response.status, errorText);
    throw new Error(`Failed to update agent settings: ${errorText}`);
  }

  return response.json();
}