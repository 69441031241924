import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  getAgentSettings,
  updateAgentSettings,
  type AgentSettings,
} from "@/api/supabase/agent";

export const useAgentSettingsQuery = () => {
  return useQuery<AgentSettings>({
    queryKey: ["agentSettings"],
    queryFn: getAgentSettings,
  });
};

export const useUpdateAgentSettingsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<AgentSettings, Error, Partial<AgentSettings>>({
    mutationFn: updateAgentSettings,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["agentSettings"] });
    },
    onError: (error) => {
      console.error("Mutation error:", error);
      throw error; // Re-throw to be caught by the component
    }
  });
};