import { useState, useEffect } from 'react'
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Slider } from "@/components/ui/slider"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Textarea } from "@/components/ui/textarea"
import { useAgentSettingsQuery, useUpdateAgentSettingsMutation } from "@/queries/agent"
import { useToast } from "@/components/ui/use-toast"
import { DEFAULT_AGENT_SETTINGS, AgentGoal } from '@/utils/constants'

const languages = [
  "Afrikaans", "Arabic", "Armenian", "Azerbaijani", "Belarusian", "Bosnian", "Bulgarian", "Catalan", "Chinese", "Croatian", "Czech", "Danish", "Dutch", "English", "Estonian", "Finnish", "French", "Galician", "German", "Greek", "Hebrew", "Hindi", "Hungarian", "Icelandic", "Indonesian", "Italian", "Japanese", "Kannada", "Kazakh", "Korean", "Latvian", "Lithuanian", "Macedonian", "Malay", "Marathi", "Maori", "Nepali", "Norwegian", "Persian", "Polish", "Portuguese", "Romanian", "Russian", "Serbian", "Slovak", "Slovenian", "Spanish", "Swahili", "Swedish", "Tagalog", "Tamil", "Thai", "Turkish", "Ukrainian", "Urdu", "Vietnamese", "Welsh"
]

export function AgentSettingsCard() {
  const { data: settings, isLoading, error } = useAgentSettingsQuery();
  const { mutate: updateSettings, isPending } = useUpdateAgentSettingsMutation();
  const { toast } = useToast();

  const [mainGoal, setMainGoal] = useState(DEFAULT_AGENT_SETTINGS.mainGoal)
  const [temperature, setTemperature] = useState(DEFAULT_AGENT_SETTINGS.temperature)
  const [language, setLanguage] = useState("English");
  const [customPrompt, setCustomPrompt] = useState('')

  useEffect(() => {
    if (settings?.agent_settings) {
      setMainGoal(settings.agent_settings.mainGoal as AgentGoal || DEFAULT_AGENT_SETTINGS.mainGoal);
      setTemperature(settings.agent_settings.temperature || DEFAULT_AGENT_SETTINGS.temperature);
      setLanguage(settings.agent_settings.language || DEFAULT_AGENT_SETTINGS.language);
      setCustomPrompt(settings.agent_settings.customGoalPrompt || '');
    } else {
      setLanguage("English");
    }
  }, [settings]);

  const handleSave = async () => {
    try {
      await updateSettings({
        agent_settings: {
          language,
          mainGoal,
          customGoalPrompt: mainGoal === 'custom' ? customPrompt : undefined,
          temperature,
        }
      });

      toast({
        title: "Settings saved",
        description: "Your agent settings have been updated successfully."
      });
    } catch (err) {
      console.error('Update error:', err);
      toast({
        title: "Error",
        description: err instanceof Error ? err.message : "Failed to save settings. Please try again.",
        variant: "destructive"
      });
    }
  };

  if (isLoading) {
    return <div className="flex justify-center items-center h-48">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500">Error loading settings. Please try again.</div>;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Agent Settings</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div>
          <label className="block mb-2">Language:</label>
          <Select value={language} onValueChange={setLanguage}>
            <SelectTrigger>
              <SelectValue placeholder="English" />
            </SelectTrigger>
            <SelectContent>
              {languages.map((lang) => (
                <SelectItem key={lang} value={lang.toLowerCase()}>
                  {lang}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div>
          <label className="block mb-2">Main goal:</label>
          <Select value={mainGoal} onValueChange={(value) => setMainGoal(value as AgentGoal)}>
            <SelectTrigger>
              <SelectValue placeholder="Select main goal" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={AgentGoal.GeneralChat}>General Chat</SelectItem>
              <SelectItem value={AgentGoal.Finance}>Finance</SelectItem>
              <SelectItem value={AgentGoal.NutritionAndFitness}>Nutrition and Fitness</SelectItem>
              <SelectItem value={AgentGoal.Custom}>Custom</SelectItem>
            </SelectContent>
          </Select>
        </div>
        {mainGoal === AgentGoal.Custom && (
          <Textarea
            placeholder="Enter custom prompt"
            value={customPrompt}
            onChange={(e) => setCustomPrompt(e.target.value)}
          />
        )}
        <div>
          <label className="block mb-2">Temperature: {temperature.toFixed(1)}</label>
          <Slider
            min={0}
            max={1}
            step={0.1}
            value={[temperature]}
            onValueChange={([value]) => setTemperature(value)}
          />
          <p className="text-sm text-white-500 mt-2">
            Controls how creative vs focused the AI responses will be. Higher values (closer to 1.0) result in more varied and creative responses, while lower values (closer to 0.0) make responses more focused and deterministic.
          </p>
        </div>
        <div>
          <label className="block mb-2">Knowledge base:</label>
          <p className="text-sm text-gray-500">Coming Soon</p>
        </div>
        <div className="flex justify-end mt-4">
          <Button onClick={handleSave} disabled={isPending}>
            {isPending ? 'Saving...' : 'Save Changes'}
          </Button>
        </div>
      </CardContent>
    </Card>
  )
}