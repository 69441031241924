import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { MessageCircle, Mic, Video, Link, File, Image, Send } from 'lucide-react'
import { useQuery } from "@tanstack/react-query"
import { getStats } from "@/api/supabase/profiles"
import { useProfileQuery } from "@/queries/profiles"
import { DEFAULT_AGENT_STATS } from "@/utils/constants";

function StatsIcon({ Icon, count, isSent = false }: { Icon: React.ElementType, count: number, isSent?: boolean }) {
  return (
    <div className="flex items-center">
      <Icon className="mr-1" size={16} />
      <span className="text-sm">{count}</span>
      {isSent && <Send className="ml-1" size={12} />}
    </div>
  )
}

export function PersonalAgentStatsCard() {
  const { data: profile } = useProfileQuery();
  
  const { data: profileStats, isPending: profileStatsLoading } = useQuery({
    queryKey: ["profileStats"],
    queryFn: () => getStats(),
    enabled: !!profile,
  });

  if (profileStatsLoading) {
    return (
      <Card>
        <CardContent className="text-center py-6">
          <p>Loading stats...</p>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Personal Agent Stats</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-5 gap-4 mb-4">
          <StatsIcon Icon={MessageCircle} count={profileStats?.messagesSeen || DEFAULT_AGENT_STATS.messagesSeen} />
          <StatsIcon Icon={Mic} count={profileStats?.voiceMemosSeen || DEFAULT_AGENT_STATS.voiceMemosSeen} />
          <StatsIcon Icon={Video} count={profileStats?.videoNotesSeen || DEFAULT_AGENT_STATS.videoNotesSeen} />
          <StatsIcon Icon={Link} count={profileStats?.linksSeen || DEFAULT_AGENT_STATS.linksSeen} />
          <StatsIcon Icon={File} count={profileStats?.filesSeen || DEFAULT_AGENT_STATS.filesSeen} />
          <StatsIcon Icon={Image} count={profileStats?.photosSeen || DEFAULT_AGENT_STATS.photosSeen} />
          <StatsIcon Icon={File} count={profileStats?.documentsSeen || DEFAULT_AGENT_STATS.documentsSeen} />
          <StatsIcon Icon={MessageCircle} count={profileStats?.messagesSent || DEFAULT_AGENT_STATS.messagesSent} isSent />
          <StatsIcon Icon={Mic} count={profileStats?.voiceMemosSent || DEFAULT_AGENT_STATS.voiceMemosSent} isSent />
          <StatsIcon Icon={Video} count={profileStats?.videoNotesSent || DEFAULT_AGENT_STATS.videoNotesSent} isSent />
        </div>
        <div className="flex justify-between items-center">
          <div>Chat credits balance: {profileStats?.credits || 0}</div>
          <Button>Buy more chat credits</Button>
        </div>
      </CardContent>
    </Card>
  )
}