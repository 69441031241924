'use client'

import { useState } from 'react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { PersonalTab } from '@/components/agents/PersonalTab'
import { GroupsTab } from '@/components/agents/GroupsTab'
import PageWrapper from "@/components/layout/PageWrapper"

export default function AgentSettingPage() {
  const [activeTab, setActiveTab] = useState('personal')
  const [isLoading, setIsLoading] = useState(false)

  const handleTabChange = (value: string) => {
    setIsLoading(true)
    try {
      setActiveTab(value)
    } catch (error) {
      console.error('Error changing tabs:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <PageWrapper>
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold mb-6">Agent Settings</h1>
        <Tabs value={activeTab} onValueChange={handleTabChange}>
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="personal">Personal</TabsTrigger>
            <TabsTrigger value="groups">Groups</TabsTrigger>
          </TabsList>
          <TabsContent value="personal">
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              <PersonalTab />
            )}
          </TabsContent>
          <TabsContent value="groups">
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              <GroupsTab />
            )}
          </TabsContent>
        </Tabs>
      </div>
    </PageWrapper>
  )
}