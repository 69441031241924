import { PersonalAgentStatsCard } from './PersonalAgentStatsCard'
import { ChatSettingsCard } from './ChatSettingCard'
import { AgentSettingsCard } from './AgentSettingsCard'

export function PersonalTab() {
  return (
    <div className="space-y-6">
      <PersonalAgentStatsCard />
      <ChatSettingsCard />
      <AgentSettingsCard />
    </div>
  )
}